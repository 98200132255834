import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SourcesService } from "../sources.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";

import { Source, Broadcaster } from "../../../models/shared";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators, FormControl, FormArray } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
import { AudioSourcesConfigurationControl } from "src/app/components/shared/zx-ndi-audio-sources/zx-ndi-audio-sources.component";

@Component({
    selector: "app-source-form-ndi",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormNdiComponent implements OnInit {
    source: Source;
    private sources: Source[];
    sourceNames: string[];

    private existingSource: Source;

    private action: string;
    loading = true;
    saving = false;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    multiGpu = false;

    transcodeProfile: TranscodingProfile;
    ndiStreams: string[];

    private maxBitrates = Constants.maxBitrates;
    constants = Constants;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private ss = inject(SourcesService);
    private broadcastersService = inject(BroadcastersService);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    private targetBXsBase = this.targetBXs;

    private clusterBroadcasters: Broadcaster[];

    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    audioSourcesConfigurationGroup: AudioSourcesConfigurationControl = new FormArray([new FormControl([])]);

    private prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
                this.source.muted = this.source.active_mute ? 1 : 0;
            }

            if (this.source) {
                this.transcodeProfile = this.source.transcodeProfile;
                if (this.source.broadcaster_cluster_id) {
                    this.clusterSelectionChange(this.source.broadcaster_cluster_id, false);
                }

                if (this.source.gpu_id == null) this.source.gpu_id = 0;
                else this.multiGpu = true;
                this.updateAudioSourcesConfigurationGroup(this.source.ndi_audio_sources);
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new Source();
            this.source.type = "ndi";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }
    private resetForm() {
        // Source
        this.source.mediaconnect_mode = "pull";
        this.source.latency = 4000;
        this.source.content_analysis = 1;
        this.source.tr101_analysis = 1;
        this.source.monitor_pids_change = 0;
        this.source.traceroute_history = 0;
        this.source.output_nic = "";
        this.tagsControl.setValue([]);
        this.source.broadcaster_cluster_id = null;
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.source.transcode_source_id = null;
        this.nameControl.setValue(null);
        this.source.allow_outputs = 0;
        this.source.outputs_password = null;
        this.source.transcode_profile_id = null;
        this.source.transcode_cbr_kbps = null;
        this.source.gpu_id = 0;
        this.source.audio_ref_level = 0;
        this.source.webrtc_mode = "";
        this.source.location = {};
        this.source.autopull_latency = null;
        // this.source.webrtc_thumbnail = 0;
        this.source.billing_code = null;
        this.source.billing_password = null;
        this.source.autopull_billing_code = null;
        this.source.autopull_billing_password = null;
        this.transcodeProfile = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        this.updateAudioSourcesConfigurationGroup();
    }

    updateAudioSourcesConfigurationGroup(channelsToAdd?: number[][]) {
        this.audioSourcesConfigurationGroup.clear();
        this.audioSourcesConfigurationGroup.reset();
        channelsToAdd?.length
            ? channelsToAdd.forEach(channels => this.audioSourcesConfigurationGroup.push(new FormControl(channels)))
            : this.audioSourcesConfigurationGroup.push(new FormControl([]));
    }

    async targetSelectionChange(id: number) {
        this.ndiStreams = [];
        let relevantIds: number[];
        if (id >= 0) relevantIds = [id];
        if (id === -1 || id === -4) relevantIds = this.clusterBroadcasters?.map(bx => bx.id) || [];
        if (id === -2) relevantIds = this.clusterBroadcasters?.filter(b => !b.is_backup).map(bx => bx.id) || [];
        if (id === -3) relevantIds = this.clusterBroadcasters?.filter(b => !!b.is_backup).map(bx => bx.id) || [];

        const ndiStreams = (
            await Promise.all(relevantIds.map(id => this.broadcastersService.getBroadcasterNdiStreams(id)))
        ).filter(stream => Array.isArray(stream));

        if (ndiStreams.length) {
            this.ndiStreams = _.flatten(ndiStreams as string[][]);
        }
        if (!this.ndiStreams.includes(this.source.input_id)) this.source.input_id = null;
    }

    async clusterSelectionChange(id: number, clusterHasChanged: boolean) {
        //  Clear specific broadcser selection since cluster is about to change.
        if (clusterHasChanged && this.source && this.source.target_broadcaster_id >= 0)
            this.source.target_broadcaster_id = undefined;

        this.getClusterSourceNames(id);
        await this.getTargetBroadcasters(id);

        if (this.source.target_broadcaster_id != null) this.targetSelectionChange(this.source.target_broadcaster_id);
    }

    private getClusterSourceNames(id: number) {
        const filteredSources = _.filter(this.sources, source => id === source.broadcaster_cluster_id);
        let filteredSourceNames = _.map(filteredSources, "name");

        if (this.isEdit) filteredSourceNames = _.without(filteredSourceNames, this.source.name);

        this.sourceNames = filteredSourceNames;
    }

    private async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }
        this.clusterBroadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
        if (this.clusterBroadcasters && this.clusterBroadcasters.length > 0) {
            this.targetBXs = this.targetBXsBase.concat(
                _.map(this.clusterBroadcasters, broadcaster => {
                    return {
                        id: broadcaster.id,
                        name: broadcaster.name,
                        type: "broadcaster",
                        generalStatus: broadcaster.generalStatus
                    };
                })
            );
        }
        this.targetBXsLoading = false;
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const sourceName = params.name;
        const sourceId = urlBuilder.decode(params.sourceId);
        this.action = params.action;

        await firstValueFrom(this.ss.refreshSources(true));
        this.sources = await firstValueFrom(this.ss.sources);

        if (sourceName && sourceId) {
            this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));

            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                await firstValueFrom(this.ss.refreshSource(this.source));
                this.source = _.cloneDeep(this.ss.getCachedSource(sourceName, null, sourceId));
            }
            this.existingSource = _.cloneDeep(this.source);
        }
        this.loading = false;

        this.prepForm();
    }

    async onSubmit() {
        this.saving = true;

        const maxBitrate =
            this.source.transcode_cbr_kbps != null
                ? this.maxBitrates.find(mb => mb.value > this.source.transcode_cbr_kbps)
                : this.maxBitrates[this.maxBitrates.length - 1];

        const audioChannelsConfig = this.audioSourcesConfigurationGroup.value.filter(channels => !!channels.length);
        const sourceModel = {
            protocol: "ndi",
            name: this.isEdit ? undefined : this.nameControl.value,
            broadcaster_cluster_id: this.source.broadcaster_cluster_id,
            feeder_id: null,
            broadcaster_id: null,
            target_broadcaster_id: this.source.target_broadcaster_id,
            input_id: this.source.input_id,
            max_bitrate: maxBitrate ? maxBitrate.value : this.source.transcode_cbr_kbps * 1.5,
            latency: null,
            monitor_pids_change: this.source.monitor_pids_change ? 1 : 0,
            traceroute_history: null,
            content_analysis: this.source.content_analysis ? 1 : 0,
            tr101_analysis: this.source.tr101_analysis ? 1 : 0,
            monitor_cei608708_cc: this.source.monitor_cei608708_cc ? 1 : 0,
            gpu_id: this.multiGpu ? this.source.gpu_id : null,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            password: "",
            encryption: "none",
            encryption_key: "",
            allow_outputs: this.source.allow_outputs ? 1 : 0,
            outputs_password: this.source.outputs_password || "",
            transcode_profile_id: this.transcodeProfile.id,
            transcode_cbr_kbps: this.source.transcode_cbr_kbps,
            report_scte_warnings: 0,
            process_scte_reports: 0,
            disable_autopull: this.source.disable_autopull,
            autopull_latency: this.source.autopull_latency,
            audio_ref_level: this.source.audio_ref_level,
            webrtc_mode: this.source.webrtc_mode,
            location: this.source.location.address,
            muted: this.source.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.source.is_enabled,
            // webrtc_thumbnail: this.source.webrtc_thumbnail
            billing_code: this.source.billing_code,
            billing_password: this.source.billing_password,
            autopull_billing_code: this.source.autopull_billing_code,
            autopull_billing_password: this.source.autopull_billing_password,
            autopull_mtu: this.source.autopull_mtu,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec,
            ndi_audio_sources: audioChannelsConfig.length ? audioChannelsConfig : null,
            hide_thumbnail: this.source.hide_thumbnail,
            enable_scte35_insertion: this.source.enable_scte35_insertion
        };

        if (this.isEdit) {
            // filter only relevant bonding fields to prevent unneccesary updates
            this.existingSource.bondedLinks = this.existingSource.bondedLinks?.map(l =>
                _.extend({
                    nic_ip: l.nic_ip,
                    device: l.device,
                    max_bitrate: l.max_bitrate,
                    backup: l.backup
                })
            );
            const changedData = this.sharedService.getZixiObjDiff(sourceModel, this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart ndi source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update ndi source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(urlBuilder.getRegularSourceUrl(this.source.id, this.source.name));
            }
        } else {
            const result = await this.ss.addSource(sourceModel);
            if (result) {
                this.mixpanelService.sendEvent("create ndi source");
                this.router.navigate(urlBuilder.getRegularSourceUrl(result.id, result.name));
            } else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate(urlBuilder.getRegularSourceUrl(this.existingSource.id, this.existingSource.name));
        else this.router.navigate([Constants.urls.sources]);
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }
}
