import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SharedService } from "../../../services/shared.service";

import {
    MediaConnectSource,
    BroadcasterInput,
    FeederInput,
    OutputNIC,
    MediaLiveInputDevice,
    ZecInput
} from "../../../models/shared";
import { MediaConnectSourcesService } from "../mediaconnect-sources.service";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { FeederInputPipe } from "src/app/pipes/feeder-input.pipe";
import { BroadcasterInputPipe } from "src/app/pipes/broadcaster-input.pipe";
import { AmazonAwsService } from "../../configuration/amazon-aws/amazon-aws.service";
import { AmazonAWS } from "../../configuration/amazon-aws/amazon-aws";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";
import { ZecsService } from "../../zecs/zecs.service";
import { ControlContainer, UntypedFormControl, NgForm, Validators } from "@angular/forms";
import { ZecInputPipe } from "src/app/pipes/zec-input.pipe";

type SourceProtocol =
    | "zixi-push"
    | "rtp-fec"
    | "rtp"
    | "rist"
    | "srt-listener"
    | "cdi"
    | "st2110-jpegxs"
    | "elemental_link";

@Component({
    selector: "app-source-form-to-mediaconnect",
    templateUrl: "./source-form.component.html",
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class SourceFormToMediaConnectComponent implements OnInit {
    protocol: SourceProtocol;
    source: MediaConnectSource;
    private sources: MediaConnectSource[];
    sourceNames: string[];

    private existingSource: MediaConnectSource;

    maxBitrates = Constants.maxBitrates;

    private action: string;
    loading = true;
    saving = false;
    constants = Constants;
    isEdit = false;
    isClone = false;
    sourceType: "feeder" | "broadcaster" | "zec" | "other";

    awsAccounts: AmazonAWS[] = [];
    awsAccountRegionsLoading = false;
    awsRegions: { id: string; name: string }[] = [];

    awsElementalLinks: MediaLiveInputDevice[] = [];
    awsElementalLinksLoading = false;

    private transcodeProfile: TranscodingProfile | null = null;

    bondingOption: number | boolean;

    broadcasterDetailsLoading = false;
    broadcasterInputs: BroadcasterInput[] = [];
    broadcasterOutputsAll: OutputNIC[] = [];
    broadcasterOutputsFilter: string;
    broadcasterOutputNICs: OutputNIC[] = [];
    selectedBroadcasterOutputNICs: OutputNIC[] = [];

    feederDetailsLoading = false;
    feederInputs: FeederInput[] = [];
    feederOutputsAll: OutputNIC[] = [];
    feederOutputsFilter: string;
    feederOutputNICs: OutputNIC[] = [];
    selectedFeederOutputNICs: OutputNIC[] = [];

    zecDetailsLoading = false;
    zecInputs: ZecInput[] = [];
    zecOutputsAll: OutputNIC[] = [];
    zecOutputsFilter: string;
    zecOutputNICs: OutputNIC[] = [];
    selectedZecOutputNICs: OutputNIC[] = [];

    page = 1;
    pageSize = 10;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private ss = inject(MediaConnectSourcesService);
    private broadcastersService = inject(BroadcastersService);
    private feederService = inject(ZecsService);
    private sharedService = inject(SharedService);
    private modalService = inject(ModalService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);
    private feederInputPipe = inject(FeederInputPipe);
    private aas = inject(AmazonAwsService);
    private broadcasterInputPipe = inject(BroadcasterInputPipe);
    private zecInputPipe = inject(ZecInputPipe);

    private modelProtocol(p: SourceProtocol): string {
        switch (p) {
            case "elemental_link": //  Elemental link sources are distinguished by elemental_link_id field. So far protocol was set to zixi-push
            case "zixi-push":
                return "zixi-push";
            default:
                return p;
        }
    }

    private getSourceProtocol(s: MediaConnectSource): SourceProtocol {
        if (s.elemental_link_id != null) return "elemental_link";
        return s.protocol as SourceProtocol;
    }

    private prepForm() {
        if (this.source) {
            this.protocol = this.getSourceProtocol(this.source);
        }

        if (this.action) {
            this.tagsControl.setValue(this.source.resourceTags);
            if (this.action === "edit") {
                this.isEdit = true;
                this.nameControl.setValue(this.source.name);
            } else if (this.action === "clone") {
                this.isClone = true;
                this.source.name = "";
            }

            if (this.source) {
                if (this.source.feeder_id) {
                    this.getFeederDetails(this.source.feeder_id);
                    this.sourceType = "feeder";
                } else if (this.source.broadcaster_id) {
                    this.getBroadcasterDetails(this.source.broadcaster_id);
                    this.sourceType = "broadcaster";
                } else if (this.source.zec_id) {
                    this.getZecDetails(this.source.zec_id);
                    this.sourceType = "zec";
                } else if (this.source.elemental_link_account_id) {
                    this.updateAWSAccountRegions();
                    this.updateAWSRegion();
                    this.transcodeProfile = this.source.transcodingProfile;
                } else if (["cdi", "st2110-jpegxs"].includes(this.protocol)) {
                    this.updateAWSAccountRegions();
                    this.updateAWSRegion();
                } else {
                    this.sourceType = "other";
                }

                this.source.output_nic = this.source.output_nic || "";

                // Bonding
                if (this.source.bondedLinks && this.source.bondedLinks.length !== 0) {
                    this.bondingOption = 1;
                }
                if (this.source.autobond === 1) {
                    this.bondingOption = 2;
                } else {
                    if (this.source.bondedLinks && this.source.bondedLinks.length !== 0) {
                        this.bondingOption = 1;
                    } else {
                        this.bondingOption = 0;
                    }
                }
            }
        }

        if (!this.source && !this.isClone && !this.isEdit) {
            this.source = new MediaConnectSource();
            this.protocol = "zixi-push";
            this.sourceType = "feeder";
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("SOURCE", this.action, this.source);
    }

    resetForm() {
        // MediaConnectSource
        this.source.latency = 4000;
        this.source.output_nic = "";
        this.source.broadcaster_id = null;
        this.source.feeder_id = null;
        this.source.zec_id = null;
        this.source.autopull_mtu = null;
        this.source.freeze_detection_timeout_sec = 10;
        this.source.blank_detection_timeout_sec = 10;
        // UI
        this.bondingOption = 0;
        this.broadcasterInputs = [];
        this.broadcasterOutputsAll = [];
        this.broadcasterOutputNICs = [];
        this.selectedBroadcasterOutputNICs = [];
        this.feederInputs = [];
        this.feederOutputsAll = [];
        this.feederOutputNICs = [];
        this.selectedFeederOutputNICs = [];
        this.zecInputs = [];
        this.zecOutputsAll = [];
        this.zecOutputNICs = [];
        this.selectedZecOutputNICs = [];

        this.transcodeProfile = null;

        if (["cdi", "st2110-jpegxs"].includes(this.protocol)) {
            this.source.latency = 200;
        }
    }

    async getFeederDetails(id: number, clearConfig?: boolean) {
        this.feederInputs = [];
        this.feederOutputsAll = [];
        this.feederOutputNICs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedFeederOutputNICs = [];
        }

        this.feederDetailsLoading = true;
        if (!id) {
            this.feederDetailsLoading = false;
            return;
        }

        const feeder = await this.feederService.refreshZec(id, "FEEDER", true).toPromise();
        if (feeder && feeder.status) {
            if (feeder.status.inputs) {
                this.feederInputs = this.sharedService.sort(
                    (feeder.status.inputs as FeederInput[]) || [],
                    "name",
                    "asc"
                );
            } else {
                this.feederInputs = [];
            }
            if (feeder.status.nics) {
                this.feederOutputsAll = feeder.status.nics || [{ name: "Any", value: "", nic: "" }];
                this.feederOutputNICs = feeder.status.nics || [];
                if (!feeder.status.nics || feeder.status.nics.length === 0) this.source.output_nic = "";
            } else {
                this.feederOutputsAll = [{ name: "Any", value: "", nic: "" }];
                this.feederOutputNICs = [];
                this.source.output_nic = "";
            }
        } else {
            this.feederInputs = [];
            this.feederOutputsAll = [{ name: "Any", value: "", nic: "" }];
            this.feederOutputNICs = [];
            this.source.output_nic = "";
        }

        // reset selected input if it no longer exists on the bx
        if (!this.feederInputs.find(input => input.name === this.source.input_id)) this.source.input_id = null;

        if (!clearConfig && this.bondingOption === 1) {
            if (this.sourceType === "feeder")
                this.source.bondedLinks
                    .map(l => {
                        l.max_bitrate /= 1000;
                        l.name = l.nic_ip;
                        l.nic = l.nic_ip;
                        return l;
                    })
                    .forEach(l => this.selectFeederOutputNIC(l));
        }

        this.feederDetailsLoading = false;
    }

    async getBroadcasterDetails(id: number, clearConfig?: boolean) {
        this.broadcasterInputs = [];
        this.broadcasterOutputsAll = [];
        this.broadcasterOutputNICs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedBroadcasterOutputNICs = [];
        }
        this.broadcasterDetailsLoading = true;
        if (!id) {
            this.broadcasterDetailsLoading = false;
            return;
        }
        const broadcaster = await this.broadcastersService.refreshBroadcaster(id, true).toPromise();

        if (broadcaster && broadcaster.status) {
            if (broadcaster.status.inputs) {
                this.broadcasterInputs = this.sharedService.sort(broadcaster.status.inputs || [], "id", "asc");
            } else {
                this.broadcasterInputs = [];
            }
            if (broadcaster.status.nics) {
                this.broadcasterOutputsAll = broadcaster.status.nics || [{ name: "Any", value: "", nic: "" }];
                this.broadcasterOutputNICs = broadcaster.status.nics || [];
                if (!broadcaster.status.nics || broadcaster.status.nics.length === 0) this.source.output_nic = "";
            } else {
                this.broadcasterOutputsAll = [{ name: "Any", value: "", nic: "" }];
                this.broadcasterOutputNICs = [];
                this.source.output_nic = "";
            }
        } else {
            this.broadcasterInputs = [];
            this.broadcasterOutputsAll = [{ name: "Any", value: "", nic: "" }];
            this.broadcasterOutputNICs = [];
            this.source.output_nic = "";
        }

        // reset selected input if it no longer exists on the bx
        if (!this.broadcasterInputs.find(input => input.id === this.source.input_id)) this.source.input_id = null;

        if (!clearConfig && this.bondingOption === 1) {
            if (this.sourceType === "broadcaster")
                this.source.bondedLinks
                    .map(l => {
                        l.max_bitrate /= 1000;
                        l.name = l.nic_ip;
                        l.nic = l.nic_ip;
                        return l;
                    })
                    .forEach(l => this.selectBroadcasterOutputNIC(l));
        }

        this.broadcasterDetailsLoading = false;
    }

    async getZecDetails(id: number, clearConfig?: boolean) {
        this.zecInputs = [];
        this.zecOutputsAll = [];
        this.zecOutputNICs = [];
        if (clearConfig) {
            this.source.output_nic = "";
            this.selectedZecOutputNICs = [];
        }

        this.zecDetailsLoading = true;
        if (!id) {
            this.zecDetailsLoading = false;
            return;
        }

        const zec = await this.feederService.refreshZec(id, "ZEC", true).toPromise();
        if (zec && zec.status) {
            if (zec.status.inputs) {
                this.zecInputs = this.sharedService.sort((zec.status.inputs as ZecInput[]) || [], "name", "asc");
            } else {
                this.zecInputs = [];
            }
            if (zec.status.nics) {
                this.zecOutputsAll = zec.status.nics || [{ name: "Any", value: "", nic: "" }];
                this.zecOutputNICs = zec.status.nics || [];
                if (!zec.status.nics || zec.status.nics.length === 0) this.source.output_nic = "";
            } else {
                this.zecOutputsAll = [{ name: "Any", value: "", nic: "" }];
                this.zecOutputNICs = [];
                this.source.output_nic = "";
            }
        } else {
            this.zecInputs = [];
            this.zecOutputsAll = [{ name: "Any", value: "", nic: "" }];
            this.zecOutputNICs = [];
            this.source.output_nic = "";
        }

        // reset selected input if it no longer exists on the bx
        if (!this.zecInputs.find(input => input.id === this.source.input_id)) this.source.input_id = null;

        if (!clearConfig && this.bondingOption === 1) {
            if (this.sourceType === "zec")
                this.source.bondedLinks
                    .map(l => {
                        l.max_bitrate /= 1000;
                        l.name = l.nic_ip;
                        l.nic = l.nic_ip;
                        return l;
                    })
                    .forEach(l => this.selectZecOutputNIC(l));
        }

        this.zecDetailsLoading = false;
    }

    async updateAWSAccountRegions() {
        this.awsRegions = [];

        if (this.source.elemental_link_account_id == null) return;

        this.awsAccountRegionsLoading = true;

        const regions = await this.aas.getAWSAccountRegions(this.source.elemental_link_account_id);
        if (regions) {
            const awsRegions = [];
            Object.entries(regions).forEach(([key, value]) => {
                awsRegions.push({ id: key, name: value });
            });
            this.awsRegions = awsRegions;
        }

        this.awsAccountRegionsLoading = false;
    }

    async updateAWSRegion() {
        if (this.source.elemental_link_account_id == null || this.source.elemental_link_region == null) return;
        this.awsElementalLinksLoading = true;

        this.awsElementalLinks = await this.ss.listElementalLinks(
            this.source.elemental_link_account_id,
            this.source.elemental_link_region
        );

        if (!this.awsElementalLinks.find(l => l.Id === this.source.elemental_link_id)) {
            this.source.elemental_link_id = null;
        }
        this.awsElementalLinksLoading = false;
    }

    selectFeederOutputNIC(output: OutputNIC) {
        this.selectedFeederOutputNICs.push(Object.assign({ backup: output.backup || 0, nic_ip: output.value }, output));
        this.feederOutputNICs = this.feederOutputNICs.filter(o => {
            return o.name !== output.name;
        });
    }

    deselectFeederOutputNIC(output: OutputNIC) {
        this.feederOutputNICs.push(output);
        this.feederOutputNICs = this.sharedService.sort(this.feederOutputNICs || [], "name", "asc");
        this.selectedFeederOutputNICs = this.selectedFeederOutputNICs.filter(o => {
            return o.name !== output.name;
        });
    }

    selectBroadcasterOutputNIC(output: OutputNIC) {
        this.selectedBroadcasterOutputNICs.push(
            Object.assign({ backup: output.backup || 0, nic_ip: output.value }, output)
        );
        this.broadcasterOutputNICs = this.broadcasterOutputNICs.filter(o => {
            return o.nic !== output.nic;
        });
    }

    deselectBroadcasterOutputNIC(output: OutputNIC) {
        this.broadcasterOutputNICs.push(output);
        this.broadcasterOutputNICs = this.sharedService.sort(this.broadcasterOutputNICs || [], "name", "asc");
        this.selectedBroadcasterOutputNICs = this.selectedBroadcasterOutputNICs.filter(o => {
            return o.nic !== output.nic;
        });
    }

    selectZecOutputNIC(output: OutputNIC) {
        this.selectedZecOutputNICs.push(Object.assign({ backup: output.backup || 0, nic_ip: output.value }, output));
        this.zecOutputNICs = this.zecOutputNICs.filter(o => {
            return o.nic !== output.nic;
        });
    }

    deselectZecOutputNIC(output: OutputNIC) {
        this.zecOutputNICs.push(output);
        this.zecOutputNICs = this.sharedService.sort(this.zecOutputNICs || [], "name", "asc");
        this.selectedZecOutputNICs = this.selectedZecOutputNICs.filter(o => {
            return o.nic !== output.nic;
        });
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const sourceName = params.name;
        this.action = params.action;

        // Sources
        await firstValueFrom(this.ss.refreshMediaConnectSources(true));
        this.sources = await firstValueFrom(this.ss.mediaconnectSources);
        if (sourceName) {
            this.source = _.cloneDeep(this.ss.getCachedMediaConnectSource(sourceName));

            // Check if source found in cache, if not get sources and source
            if (this.sharedService.isEmptyObject(this.source) || !this.source.hasFullDetails) {
                const source = this.ss.getCachedMediaConnectSource(sourceName);
                await firstValueFrom(this.ss.refreshMediaConnectSource(source, true));
                this.source = _.cloneDeep(this.ss.getCachedMediaConnectSource(sourceName));
            }
            this.existingSource = _.cloneDeep(this.source);
        }
        this.loading = false;

        // Sources

        this.sourceNames = this.sources.map(s => s.name);
        if (this.isEdit) this.sourceNames = this.sourceNames.filter(n => n !== sourceName);

        // AWS Accounts
        await firstValueFrom(this.aas.refreshAWSAccounts(true));
        this.awsAccounts = await firstValueFrom(this.aas.awsAccounts);

        this.prepForm();
    }

    async onSubmit() {
        this.saving = true;

        if (this.bondingOption === true) this.bondingOption = 1;

        if (this.bondingOption === 1) {
            if (
                (this.sourceType === "feeder" && this.selectedFeederOutputNICs.length === 0) ||
                (this.sourceType === "broadcaster" && this.selectedBroadcasterOutputNICs.length === 0) ||
                (this.sourceType === "zec" && this.selectedZecOutputNICs.length === 0)
            ) {
                this.saving = false;
                return;
            }
        }

        const sourceModel = {
            name: this.isEdit ? undefined : this.nameControl.value,
            protocol: this.modelProtocol(this.protocol),
            ingest_port: ["zixi-push", "elemental_link"].includes(this.protocol) ? 2088 : this.source.ingest_port,
            whitelist_cidr: this.source.whitelist_cidr,
            feeder_id:
                this.sourceType === "feeder" && this.source.feeder_id && this.source.feeder_id !== -1
                    ? this.source.feeder_id
                    : null,
            broadcaster_id:
                this.sourceType === "broadcaster" && this.source.broadcaster_id && this.source.broadcaster_id !== -1
                    ? this.source.broadcaster_id
                    : null,
            zec_id:
                this.sourceType === "zec" && this.source.zec_id && this.source.zec_id !== -1
                    ? this.source.zec_id
                    : null,
            input_id:
                (this.source.feeder_id && this.source.feeder_id !== -1) ||
                (this.source.broadcaster_id && this.source.broadcaster_id !== -1) ||
                (this.source.zec_id && this.source.zec_id !== -1)
                    ? this.source.input_id
                    : null,
            max_bitrate: this.source.max_bitrate,
            latency: this.source.latency,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.source.alertingProfile.id,
            output_nic: this.bondingOption === 0 && this.source.output_nic ? this.source.output_nic : "",
            autobond: this.sourceType === "feeder" && this.bondingOption === 2 ? 1 : 0,
            bondedLinks:
                this.bondingOption === 1
                    ? _.map(
                          (this.sourceType === "feeder" && this.selectedFeederOutputNICs) ||
                              (this.sourceType === "broadcaster" && this.selectedBroadcasterOutputNICs) ||
                              (this.sourceType === "zec" && this.selectedZecOutputNICs) ||
                              [],
                          output => {
                              return _.extend({}, output, { max_bitrate: output.max_bitrate * 1000 });
                          }
                      )
                    : [],
            report_scte_warnings: this.source.report_scte_warnings,
            process_scte_reports: this.source.process_scte_reports,
            muted: this.source.muted,
            elemental_link_account_id:
                this.protocol === "elemental_link" ? this.source.elemental_link_account_id : null,
            elemental_link_region: this.protocol === "elemental_link" ? this.source.elemental_link_region : null,
            elemental_link_id: this.protocol === "elemental_link" ? this.source.elemental_link_id : null,
            transcoding_profile_id:
                this.protocol === "elemental_link" && this.transcodeProfile ? this.transcodeProfile.id : null,
            autopull_mtu: this.source.autopull_mtu,
            freeze_detection_timeout_sec: this.source.freeze_detection_timeout_sec,
            blank_detection_timeout_sec: this.source.blank_detection_timeout_sec
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(sourceModel, this.existingSource, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedSource = await this.ss.updateMediaConnectSource(this.source, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedSource;

                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "SOURCE",
                        async () => {
                            const updateAndRestartSource = await this.ss.updateMediaConnectSource(this.source, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartSource) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart mediaconnect source", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate([Constants.urls.sources, "mediaconnect", this.source.name]);
                            } else this.saving = false;
                        },
                        this.source.name
                    );
                    this.saving = false;
                } else if (updatedSource) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update mediaconnect source", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate([Constants.urls.sources, "mediaconnect", this.source.name]);
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate([Constants.urls.sources, "mediaconnect", this.source.name]);
            }
        } else {
            const result = await this.ss.addMediaConnectSource(sourceModel);
            if (result) {
                this.mixpanelService.sendEvent("create mediaconnect source");
                this.router.navigate([Constants.urls.sources, "mediaconnect", result.name]);
            } else this.saving = false;
        }
    }

    back() {
        this.router.navigate([Constants.urls.sources, "new"]);
    }

    cancel() {
        if (this.isEdit || this.isClone)
            this.router.navigate([Constants.urls.sources, "mediaconnect", this.existingSource.name]);
        else this.router.navigate([Constants.urls.sources]);
    }

    broadcasterInputSearch = (term: string, item: BroadcasterInput) => {
        return this.broadcasterInputPipe.transform(item).toLowerCase().indexOf(term.toLowerCase()) > -1;
    };

    feederInputSearch = (term: string, item: FeederInput) => {
        return this.feederInputPipe.transform(item).toLowerCase().indexOf(term.toLowerCase()) > -1;
    };

    zecInputSearch = (term: string, item: ZecInput) => {
        return this.zecInputPipe.transform(item).toLowerCase().indexOf(term.toLowerCase()) > -1;
    };
}
